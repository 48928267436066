<ng-container *ngIf="model != null">
    <form class="px-1" (ngSubmit)="onSubmit()" #flowDefinitionDetails="ngForm">
        <div class="scroll-wrapper styled-scrollbar pr-2">
            <!-- Nome -->
            <ng-container>
                <div class="form-group pl-1" featureHint="flowDefinitionDetails|Name" featureHintExclusiveGroup>
                    <label for="name">Nome</label>
                    <input type="text" class="form-control" id="name" name="name" #nameElem="ngModel" maxlength="100" required
                           [(ngModel)]="model.name"
                           [disabled]="inputIsReadOnlyMode"
                    >
                </div>
                <div [hidden]="nameElem.valid || nameElem.pristine" class="alert alert-danger">Campo obrigatório (máximo de 100 caracteres)</div>
            </ng-container>

            <!-- Nome Público -->
            <ng-container>
                <div class="form-group pl-1" featureHint="flowDefinitionDetails|PublicName" featureHintExclusiveGroup>
                    <label for="publicName">Nome Público</label>
                    <input type="text" class="form-control" id="publicName" name="publicName" #publicNameElem="ngModel" maxlength="100"
                           [(ngModel)]="model.publicName"
                           [disabled]="inputIsReadOnlyMode"
                    >
                </div>
                <div [hidden]="publicNameElem.valid || publicNameElem.pristine" class="alert alert-danger">Máximo de 100 caracteres</div>
            </ng-container>

            <!-- Caixa de Fluxos -->
            <ng-container>
                <div class="form-group pl-1" featureHint="flowDefinitionDetails|FlowInbox">
                    <label for="flowInbox">Caixa de Fluxos</label>
                    <i class="fas fa-question-circle ml-1" matTooltip="Localização deste Fluxo. Influencia diretamente seu Público-alvo"></i>
                    <select class="form-control" id="flowInbox" name="flowInbox"
                            [(ngModel)]="model.unitId"
                            [disabled]="inputIsReadOnlyMode"
                            (change)="getCatalogServices($event)"
                    >
                        <option *ngFor="let option of getOrderedAdminUnitsList()" [value]="option.guid">{{option.organizacao.sigla}} - {{option.nomeCurto}}</option>
                    </select>
                </div>
            </ng-container>

            <!-- Serviço do Catálogo ES -->
            <ng-container>
                <div class="form-group pl-4" featureHint="flowDefinitionDetails|CatalogService">
                    <label for="catalogServiceDisplay">Serviço do Catálogo ES</label>
                    <i class="fas fa-question-circle ml-1"
                       matTooltip="Associar este Fluxo a um serviço do Catálogo ES. Os serviços são filtrados a partir do órgão da Caixa de Fluxos"
                    ></i>
                    <input type="text" class="form-control relevant-info-font searchable" id="catalogServiceDisplay" name="catalogServiceDisplay"
                           [placeholder]="getCatalogServicePlaceholder()"
                           [(ngModel)]="catalogServiceDisplay"
                           [matTooltip]="getCatalogServiceTooltip()"
                           [disabled]="inputIsReadOnlyMode || selectableCatalogServices.length == 0"
                           [matAutocomplete]="catalogServiceAutocomplete"
                           (input)="catalogServiceDisplayChange()"
                    >
                    <mat-autocomplete #catalogServiceAutocomplete="matAutocomplete"
                                      [displayWith]="displayCatalogServiceId"
                                      (optionSelected)="catalogServiceIdChange($event)"
                    >
                        <ng-container *ngFor="let option of selectableCatalogServices">
                            <mat-option [value]="option">{{option.description}}</mat-option>
                        </ng-container>
                    </mat-autocomplete>
                    <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearCatalogService()"></i>
                </div>
            </ng-container>

            <!-- Público-alvo -->
            <ng-container>
                <div class="form-group pl-1" featureHint="flowDefinitionDetails|FlowTarget" featureHintShowOn="right">
                    <label for="flowTarget">Público-alvo</label>
                    <i class="fas fa-question-circle ml-1" [matTooltip]="FlowTargetDescription.get(+model.targetId)"></i>
                    <select class="form-control" id="flowTarget" name="flowTarget"
                            [(ngModel)]="model.targetId"
                            [disabled]="inputIsReadOnlyMode"
                    >
                        <option *ngFor="let option of inputFlowTargetOptions" [value]="option.value">{{option.description}}</option>
                    </select>
                </div>
            </ng-container>

            <!-- Descrição pública -->
            <ng-container>
                <div class="form-group pl-1" featureHint="flowDefinitionDetails|Description" featureHintVAlign="top">
                    <label for="description">Descrição pública</label>
                    <i class="fas fa-question-circle ml-1" matTooltip="Descrição a ser exibida antes da primeira Tarefa do Fluxo na área pública do E-Flow"></i>
                    <editor name="description" *ngIf="inputShouldLoadTinyMce"
                            [init]="tinyMceOptions"
                            [disabled]="inputIsReadOnlyMode"
                            [(ngModel)]="description"
                    ></editor>
                    <textarea id="tinyMceDefinitionDetails" class="d-none"></textarea>
                </div>
            </ng-container>

            <!-- Adicionar informações a títulos do E-Flow -->
            <ng-container>
                <!-- Ativação da opção -->
                <div class="form-group checkbox-group pl-1">
                    <mat-checkbox id="shouldAddInfoToEDocsTitles" name="shouldAddInfoToEDocsTitles" color="primary"
                                  featureHint="flowDefinitionDetails|TitleInfo" featureHintShowOn="right"
                                  [(ngModel)]="configSchema.shouldAddInfoToEDocsTitles"
                                  [disabled]="inputIsReadOnlyMode"
                                  (change)="titleInfoChange()"
                    >Adicionar informações a títulos do E-Flow utilizados no E-Docs</mat-checkbox>
                    <i class="fas fa-question-circle ml-1"
                       matTooltip="Identificar títulos de Documentos capturados, Encaminhamentos, Processos etc. realizados pelo E-Flow
                                   com um dos tipos de informações listados abaixo"
                    ></i>
                </div>

                <!-- Não adicionar informações a títulos de Documentos capturados -->
                <div class="form-group checkbox-group ml-5">
                    <mat-checkbox id="shouldNotAddInfoToDocuments" name="shouldNotAddInfoToDocuments" color="primary" class="no-additional-info-documents"
                                  [(ngModel)]="configSchema.shouldNotAddInfoToDocuments"
                                  [disabled]="!configSchema.shouldAddInfoToEDocsTitles"
                    >Não adicionar informações a títulos de Documentos capturados</mat-checkbox>
                    <i class="fas fa-question-circle ml-1"
                       matTooltip="Apenas Encaminhamentos e Processos do E-Docs gerados no E-Flow apresentarão as informações adicionais aqui configuradas"
                    ></i>
                </div>

                <!-- Seletor de tipo de informação -->
                <mat-radio-group class="title-info-type ml-5" id="titleInfoType" name="titleInfoType" color="primary"
                                 [(ngModel)]="titleInfoType"
                                 [disabled]="inputIsReadOnlyMode || !configSchema.shouldAddInfoToEDocsTitles"
                                 [required]="!inputIsReadOnlyMode && configSchema.shouldAddInfoToEDocsTitles"
                                 (change)="titleInfoTypeChange()"
                >
                    <mat-radio-button [value]="TitleInfoType.UserName">Nome do usuário instanciador do Fluxo</mat-radio-button>
                    <!--<mat-radio-button [value]="TitleInfoType.UserCpf">CPF do usuário instanciador do Fluxo</mat-radio-button>-->
                    <mat-radio-button [value]="TitleInfoType.FormField">Valor de um campo do formulário</mat-radio-button>
                </mat-radio-group>

                <!-- Campo do formulário -->
                <ng-container *ngIf="titleInfoType == TitleInfoType.FormField">
                    <div class="d-flex">
                        <div class="form-field-row ml-6">
                            <div class="form-group w-100 px-0">
                                <label for="formField">Campo do Formulário</label>
                                <i class="fas fa-question-circle ml-1"
                                   matTooltip="Campo do formulário cujo valor será utilizado. Note que campos do tipo &quot;Lista de Seleção Múltipla&quot; serão ignorados"
                                ></i>
                                <select class="form-control" id="formField" name="formField"
                                        [required]="!inputIsReadOnlyMode && configSchema.shouldAddInfoToEDocsTitles && titleInfoType == TitleInfoType.FormField"
                                        [(ngModel)]="formField"
                                >
                                    <option *ngFor="let option of formFieldOptions"
                                            [value]="option.value"
                                    >{{option.description}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!-- Informação adicional -->
                <div class="form-group checkbox-group ml-5">
                    <mat-checkbox id="shouldAddInfoToEDocsTitles_Additional" name="shouldAddInfoToEDocsTitles_Additional" color="primary" class="additional-info"
                                  [(ngModel)]="configSchema.shouldAddInfoToEDocsTitles_Additional"
                                  [disabled]="shouldDisableAdditionalInfo()"
                                  (change)="titleInfoChange_Additional()"
                    >Informação adicional</mat-checkbox>
                    <i class="fas fa-question-circle ml-1"
                       matTooltip="Informação extra a ser adicionada aos títulos do E-Docs"
                    ></i>
                </div>

                <!-- Seletor de tipo de informação adicional -->
                <ng-container *ngIf="configSchema.shouldAddInfoToEDocsTitles_Additional">
                    <mat-radio-group class="title-info-type ml-6" id="titleInfoType_Additional" name="titleInfoType_Additional" color="primary"
                                     [(ngModel)]="titleInfoType_Additional"
                                     [disabled]="inputIsReadOnlyMode || !configSchema.shouldAddInfoToEDocsTitles_Additional"
                                     [required]="!inputIsReadOnlyMode && configSchema.shouldAddInfoToEDocsTitles_Additional"
                                     (change)="titleInfoTypeChange_Additional()"
                    >
                        <mat-radio-button [value]="TitleInfoType.UserName">Nome do usuário instanciador do Fluxo</mat-radio-button>
                        <!--<mat-radio-button [value]="TitleInfoType.UserCpf">CPF do usuário instanciador do Fluxo</mat-radio-button>-->
                        <mat-radio-button [value]="TitleInfoType.FormField">Valor de um campo do formulário</mat-radio-button>
                    </mat-radio-group>
                </ng-container>

                <!-- Campo do formulário -->
                <ng-container *ngIf="titleInfoType_Additional == TitleInfoType.FormField">
                    <div class="d-flex">
                        <div class="form-field-row ml-7">
                            <div class="form-group w-100 px-0">
                                <label for="formField">Campo do Formulário</label>
                                <i class="fas fa-question-circle ml-1"
                                   matTooltip="Campo do formulário cujo valor será utilizado. Note que campos do tipo &quot;Lista de Seleção Múltipla&quot; serão ignorados"
                                ></i>
                                <select class="form-control" id="formField_Additional" name="formField_Additional"
                                        [required]="!inputIsReadOnlyMode
                                            && configSchema.shouldAddInfoToEDocsTitles_Additional
                                            && titleInfoType_Additional == TitleInfoType.FormField"
                                        [(ngModel)]="formField_Additional"
                                >
                                    <option *ngFor="let option of formFieldOptions"
                                            [value]="option.value"
                                    >{{option.description}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>

        <!-- Botões -->
        <div class="form-footer text-right mb-1">
            <hr class="mt-0" />

            <!-- ConfigSchema -->
            <button type="button" class="btn btn-outline-secondary float-left ml-1"
                    (click)="showConfigSchema()"
            >Editar código-fonte</button>

            <!-- Cancelar -->
            <button type="button" class="btn btn-outline-danger mr-2"
                    (click)="closeForm()"
            >Cancelar</button>

            <!-- Salvar -->
            <button type="submit" class="btn btn-outline-primary"
                    [class.read-only]="inputIsReadOnlyMode"
                    [disabled]="!flowDefinitionDetails.form.valid || inputIsReadOnlyMode"
            >Salvar Detalhes</button>
        </div>
    </form>

    <!-- Modal do ConfigSchema -->
    <ng-template #configSchemaRef>
        <textarea id="configSchemaEditor" name="configSchemaEditor" class="form-control" #configSchemaEditorRef
                  style="height: calc(100vh - 78px); resize: none; opacity: 0; transition: opacity .3s ease-in"
                  [ngModel]="configSchemaFlat"
        ></textarea>
    </ng-template>
</ng-container>
