import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    ElementRef,
    ViewChild,
    TemplateRef
} from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { IBaseOption } from '../../../models/base.model';
import { Servico } from '../../../models/catalogo-es.model';
import { ConfigSchemaFlowDefinition } from '../../../models/config-schema.model';
import { AccessLevelType } from '../../../models/edocs.model';
import { FlowObjectType, FormSchema } from '../../../models/flow-object.model';
import { FlowDefinition } from '../../../models/flow.model';
import { AuthService } from '../../../services/auth.service';
import { CatalogoEsService } from '../../../services/catalogo-es.service';
import { FlowObjectDefinitionService } from '../../../services/flow-object-definition.service';
import { Enums } from '../../../shared/enums';
import { Utils } from '../../../shared/utils';
import { FLOW_DEFINITION_DETAILS_TINYMCE_OPTIONS } from './flow-definition-details-tinymce-options';
import { CatalogServicesFilter } from './flow-definition-details.pipe';

@Component({
    selector: 'flow-definition-details',
    templateUrl: './flow-definition-details.component.html',
    styleUrls: ['./flow-definition-details.component.scss']
})
export class FlowDefinitionDetailsComponent implements OnInit {
    // #region [ViewChild]
    @ViewChild('configSchemaRef') configSchemaRef: TemplateRef<HTMLElement>;
    @ViewChild('configSchemaEditorRef') configSchemaEditorRef: ElementRef;
    // #endregion

    // #region [Type properties]
    FlowObjectType: typeof FlowObjectType = FlowObjectType;
    AccessLevelType: typeof AccessLevelType = AccessLevelType;
    FlowTargetDescription: typeof Enums.FlowTargetDescription = Enums.FlowTargetDescription;
    TitleInfoType: typeof Enums.TitleInfoType = Enums.TitleInfoType;
    // #endregion

    // #region [properties]
    model: FlowDefinition;
    description: string;
    configSchema: ConfigSchemaFlowDefinition = new ConfigSchemaFlowDefinition();
    configSchemaFlat: string = null;
    titleInfoType: Enums.TitleInfoType = null;
    titleInfoType_Additional: Enums.TitleInfoType = null;
    formFieldOptions: IBaseOption[] = [];
    formField: string = null;
    formField_Additional: string = null;
    formFlowObjectSchemaFields: any[] = [];
    catalogServiceId: string = '';
    catalogServiceDisplay: string = '';
    catalogServices: Servico[] = [];
    selectableCatalogServices: IBaseOption[] = [];
    tinyMceOptions: any = FLOW_DEFINITION_DETAILS_TINYMCE_OPTIONS;
    // #endregion

    // #region [Input/Output]
    @Input() inputModel: FlowDefinition;
    @Input() inputIsReadOnlyMode: boolean;
    @Input() inputFlowTargetOptions: IBaseOption[];
    @Input() inputShouldLoadTinyMce: boolean;
    @Output() outputUpdateFlowDefinitionEvent = new EventEmitter<FlowDefinition>();
    @Output() outputCloseEvent = new EventEmitter<any>();
    // #endregion

    constructor(
        private dialog: MatDialog,
        private toastr: ToastrService,
        public authService: AuthService,
        private catalogServicesFilter: CatalogServicesFilter,
        private catalogoEsService: CatalogoEsService,
        private flowObjectDefinitionService: FlowObjectDefinitionService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() {
        let interval = setInterval(async () => {
            if (this.inputModel == null) return;
            clearInterval(interval);

            this.model = this.inputModel;
            this.description = this.model.description;

            if (this.model.configSchema != null) {
                Object.assign(this.configSchema, JSON.parse(this.model.configSchema));
            }

            let formFlowObject = this.model.flowObjectDefinitions.find(x => x.typeId == FlowObjectType.StartForm);
            if (formFlowObject != null && !Utils.isNullOrEmpty(this.model.ownerInfo)) {
                if (Utils.isNullOrEmpty(formFlowObject.formSchema)) {
                    const response = await this.flowObjectDefinitionService.getFormData(formFlowObject.id);

                    if (!response.isSuccess) {
                        this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                        return;
                    }

                    formFlowObject.formSchema = response.data;
                }

                let formSchema = JSON.parse(formFlowObject.formSchema) as FormSchema;
                this.formFlowObjectSchemaFields = Utils.getFormFieldsWithUsableValue(formSchema.components);
                this.formFieldOptions = this.formFlowObjectSchemaFields.map(x => ({ value: x.key, description: x.label }));
            }

            this.initConfigSchemaInfo();

            await this.getCatalogServices();
        }, 200);
    }

    // ======================
    // public methods
    // ======================

    titleInfoTypeChange() {
        if (this.titleInfoType != Enums.TitleInfoType.FormField) {
            this.formField = null;
        }
    }

    titleInfoTypeChange_Additional() {
        if (this.titleInfoType_Additional != Enums.TitleInfoType.FormField) {
            this.formField_Additional = null;
        }
    }

    titleInfoChange() {
        if (!this.configSchema.shouldAddInfoToEDocsTitles) {
            this.titleInfoType = null;
            this.configSchema.shouldAddInfoToEDocsTitles_Additional = false;
            this.configSchema.shouldNotAddInfoToDocuments = false;
            this.titleInfoChange_Additional();
        }
    }

    titleInfoChange_Additional() {
        if (!this.configSchema.shouldAddInfoToEDocsTitles_Additional) {
            this.titleInfoType_Additional = null;
        }
    }

    shouldDisableAdditionalInfo(): boolean {
        return this.inputIsReadOnlyMode
            || !this.configSchema.shouldAddInfoToEDocsTitles
            || this.titleInfoType == null
            || (
                this.titleInfoType == Enums.TitleInfoType.FormField
                && Utils.isNullOrEmpty(this.formField)
            );
    }

    getOrderedAdminUnitsList() {
        return this.authService.user?.unidadesPerfilAdministrador.sort((a, b) => {
            let first = `${a.organizacao.sigla} - ${a.nomeCurto}`;
            let second = `${b.organizacao.sigla} - ${b.nomeCurto}`;
            return first.localeCompare(second);
        });
    }

    catalogServiceDisplayChange() {
        let filteredCatalogServices = this.catalogServicesFilter.transform(this.catalogServices, this.catalogServiceDisplay);
        this.selectableCatalogServices = filteredCatalogServices.map(x => ({ value: x.guid, description: x.nome }));
    }

    catalogServiceIdChange(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.catalogServiceId = event.option.value.value;
        }

        if (this.catalogServiceId == '') {
            this.catalogServiceId = null;
            this.model.catalogServiceId = null;
        } else {
            this.model.catalogServiceId = this.catalogServiceId;
        }
    }

    displayCatalogServiceId(option: IBaseOption): string {
        return typeof option == 'string'
            ? option
            : option?.value != null && option?.value != ''
                ? option.description
                : '';
    }

    clearCatalogService() {
        this.catalogServiceId = '';
        this.model.catalogServiceId = null;
        this.catalogServiceDisplay = '';
        this.selectableCatalogServices = this.catalogServices.map(x => ({ value: x.guid, description: x.nome }));
    }

    getCatalogServiceTooltip(): string {
        return this.selectableCatalogServices.find(x => x.value == (this.catalogServiceId || this.model.catalogServiceId))?.description;
    }

    getCatalogServicePlaceholder(): string {
        return this.selectableCatalogServices.length == 0
            ? Enums.Messages.NoOptionAvailable
            : Enums.Messages.SelectAnOption;
    }

    async getCatalogServices(event?){
        let organizationId = event?.target.value
            || this.model.organizationId
            || this.authService.user?.unidadesPerfilAdministrador.find(x => x.guid == this.model.unitId)?.organizacao?.guid
            || this.getOrderedAdminUnitsList()[0].organizacao.guid;

        const response = await this.catalogoEsService.getServicosOrganizacao(organizationId);

        if (response.isSuccess) {
            this.catalogServices = response.data;
            this.selectableCatalogServices = this.catalogServices.map(x => ({ value: x.guid, description: x.nome }));

            if (event != null) {
                this.clearCatalogService();
                this.catalogServiceDisplayChange();
            } else {
                this.catalogServiceId = this.model.catalogServiceId;
            }

            if (this.model.catalogServiceId != null && this.model.catalogServiceId != '') {
                this.catalogServiceDisplay = this.selectableCatalogServices.find(x => x.value == this.model.catalogServiceId)?.description;
            }
        } else {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
        }
    }

    showConfigSchema() {
        this.resolveConfigSchemaInfo();

        this.model.description = this.description;
        let tempObject = {
            model: Utils.decycleFlowDefinition(this.model),
            configSchema: JSON.parse(JSON.stringify(this.configSchema))
        };
        delete tempObject.model.configSchema;
        delete tempObject.model.flowObjectDefinitions;

        this.configSchemaFlat = btoa(encodeURIComponent(JSON.stringify(tempObject)));

        let dialog = this.dialog.open(this.configSchemaRef, {
            minWidth: '800px'
        });

        dialog.afterOpened().subscribe(() => {
            setTimeout(() => {
                this.configSchemaEditorRef.nativeElement.scrollLeft = 0;
                this.configSchemaEditorRef.nativeElement.scrollTop = 0;
            }, 1);
            setTimeout(() => {
                this.configSchemaEditorRef.nativeElement.style.opacity = '1';
            }, 100);
        });

        dialog.afterClosed().subscribe(async () => {
            if (this.inputIsReadOnlyMode) return;

            try {
                let modalObject = JSON.parse(decodeURIComponent(atob(this.configSchemaEditorRef.nativeElement.value)));
                delete modalObject.model.configSchema;

                // checa se o tipo da origem do conteúdo é o mesmo tipo do conteúdo atual (i.e. se a origem não é um FlowObjectDefinition)
                if (modalObject.model.targetId == null) {
                    this.toastr.error(Enums.Messages.InvalidConfigSchemaSourceType, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                // checa se o código-fonte foi alterado
                if (JSON.stringify(tempObject) != JSON.stringify(modalObject)) {
                    this.configSchema = JSON.parse(JSON.stringify(modalObject.configSchema)) as ConfigSchemaFlowDefinition;
                    this.model.catalogServiceId = modalObject.model.catalogServiceId;
                    this.model.description = modalObject.model.description;
                    this.model.name = modalObject.model.name;
                    this.model.publicName = modalObject.model.publicName;
                    this.model.targetId = modalObject.model.targetId;
                    this.model.unitId = modalObject.model.unitId;
                    this.description = this.model.description;
                }
            } catch (error) {
                this.toastr.error(Enums.Messages.InvalidFlowDefinitionConfigSchemaSource, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.initConfigSchemaInfo();

            await this.getCatalogServices();
        });
    }

    onSubmit() {
        if (this.inputIsReadOnlyMode) return false;

        this.model.description = this.description;

        this.resolveConfigSchemaInfo();
        this.model.configSchema = JSON.stringify(this.configSchema);

        this.outputUpdateFlowDefinitionEvent.emit(this.model);
    }

    closeForm() {
        this.outputCloseEvent.emit();
    }

    // ======================
    // private methods
    // ======================

    private initConfigSchemaInfo() {
        this.formField = this.configSchema.formFieldNameAddedToEDocsTitles;
        if (this.configSchema.shouldAddInfoToEDocsTitles) {
            this.titleInfoType = this.configSchema?.addUserNameToEDocsTitles
                ? Enums.TitleInfoType.UserName
                : this.configSchema?.addUserCpfToEDocsTitles
                    ? Enums.TitleInfoType.UserCpf
                    : !Utils.isNullOrEmpty(this.configSchema?.formFieldNameAddedToEDocsTitles)
                        ? Enums.TitleInfoType.FormField
                        : null;
        }
        this.titleInfoTypeChange();

        this.formField_Additional = this.configSchema.formFieldNameAddedToEDocsTitles_Additional;
        if (this.configSchema.shouldAddInfoToEDocsTitles_Additional) {
            this.titleInfoType_Additional = this.configSchema?.addUserNameToEDocsTitles_Additional
                ? Enums.TitleInfoType.UserName
                : this.configSchema?.addUserCpfToEDocsTitles_Additional
                    ? Enums.TitleInfoType.UserCpf
                    : !Utils.isNullOrEmpty(this.configSchema?.formFieldNameAddedToEDocsTitles_Additional)
                        ? Enums.TitleInfoType.FormField
                        : null;
        }
        this.titleInfoTypeChange_Additional();
    }

    private resolveConfigSchemaInfo() {
        this.configSchema.addUserNameToEDocsTitles = this.titleInfoType == Enums.TitleInfoType.UserName;
        this.configSchema.addUserCpfToEDocsTitles = this.titleInfoType == Enums.TitleInfoType.UserCpf;
        this.configSchema.formFieldNameAddedToEDocsTitles = this.formField;

        this.configSchema.addUserNameToEDocsTitles_Additional = this.titleInfoType_Additional == Enums.TitleInfoType.UserName;
        this.configSchema.addUserCpfToEDocsTitles_Additional = this.titleInfoType_Additional == Enums.TitleInfoType.UserCpf;
        this.configSchema.formFieldNameAddedToEDocsTitles_Additional = this.formField_Additional;
    }
}
